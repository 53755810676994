import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  api = '/';
  key;
  username: '';
  password: '';
  MailingLists = [];
  MailingListsFiltered = [];
  ActiveItemName = null;
  ActiveItem;
  DomainUsers = [];
  ShowAll: boolean;

  constructor(private http: HttpClient, private toastr: ToastrService) {
    this.ShowAll = false;
  }

  assignCopy() {
    this.MailingListsFiltered = Object.assign([], this.MailingLists);
  }

  filterItem(value) {
    if (!value) {
      this.assignCopy();
    }
    this.MailingListsFiltered = Object.assign([], this.MailingLists).filter(
      item => item.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
  }


  async ngOnInit() {
    this.key = localStorage.getItem('key');
    if (this.key) {
      this.MailingLists = await this.http.get(this.api + 'aliases').toPromise() as [String];
      this.assignCopy();
      this.getDomainUsers();
    }
    this.ShowAll = true;
  }

  async login() {
    this.key = (await this.http.post(this.api + 'authenticate', {
      username: this.username,
      password: this.password
    }).toPromise() as { key: string }).key;
    localStorage.setItem('key', this.key);
    this.MailingLists = await this.http.get(this.api + 'aliases').toPromise() as [string];
    this.assignCopy();
    this.getDomainUsers();
  }

  async getDomainUsers() {
    this.DomainUsers = await this.http.get(this.api + 'domainusers').toPromise() as [string];
  }

  async setActiveList(item) {
    this.ActiveItemName = item;
    this.ActiveItem = await this.http.get(this.api + 'aliases/' + this.ActiveItemName).toPromise();
  }

  async saveItem() {
    const ResponseObject = await this.http
      .post(this.api + 'aliases/' + this.ActiveItemName, this.ActiveItem)
      .toPromise() as { saved: boolean, valid: boolean, errors: string };
    console.log(ResponseObject);
    if (ResponseObject.saved && ResponseObject.valid) {
      this.showSuccess();
      this.setActiveList(this.ActiveItemName);
    } else {
      this.showError(ResponseObject.errors);
    }
  }

  closeItem() {
    this.ActiveItemName = null;
    this.ActiveItem = null;
  }

  logout() {
    this.key = null;
    localStorage.removeItem('key');
  }

  toggleList() {
    this.ShowAll = !this.ShowAll;
  }

  showSuccess() {
    this.toastr.success('Mailing-Liste erfolgreich gespeichert', 'Erfolg!');
  }

  showError(error) {
    this.toastr.error('Mailing-Liste konnte nicht gespeichert werden. Fehlerhafte Einträge: ' + error, 'Fehler!');
  }
}
